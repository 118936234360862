/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

//Since I used context API ... the following setting has to be turned on to access Context in every pages
//https://www.gatsbyjs.org/blog/2019-01-31-using-react-context-api-with-gatsby/
import React from "react"
import GlobalState from "./src/codes/contexts/global/globalState";

export const wrapRootElement = ({ element }) => (
  <GlobalState>{element}</GlobalState>
)