export const HEADER_HEIGHT = "HEADER_HEIGHT";
export const FOOTER_HEIGHT = "FOOTER_HEIGHT";
export const HAMBURGER_STATE = "HAMBURGER_STATE";
export const INDICATION = "INDICATION";
export const EXTERNAL_LINK_MODAL = "EXTERNAL_LINK_MODAL";
export const EXTERNAL_LINK_URL = "EXTERNAL_LINK_URL";
export const HCP_MODAL = "HCP_MODAL";
export const HCP_URL = "HCP_URL";
export const REFERENCES = "REFERENCES";
export const CURRENT_PAGE_NAME = "CURRENT_PAGE_NAME";
