import React, { useState, useEffect, useReducer } from "react";
import {
  HEADER_HEIGHT,
  FOOTER_HEIGHT,
  HAMBURGER_STATE,
  INDICATION,
  EXTERNAL_LINK_MODAL,
  EXTERNAL_LINK_URL,
  HCP_MODAL,
  HCP_URL,
  REFERENCES,
  CURRENT_PAGE_NAME,
} from "../ActionTypes";
import GlobalContext from "./globalContext";
import GlobalReducer from "./globalReducer";
import { useWindowSize } from "hooks";
import AppConfigs from "../../../../app.settings";

const GlobalState = (props) => {
  const initialState = {
    headerHeight: 0,
    footerHeight: 0,
    hamburgerState: false,
    currentIndication: "",
    externalLinkModalState: false,
    externalLinkURL: "",
    hcpModalState: false,
    hcpURL: "",
    references: null,
  };

  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  //Define reducer and initial state
  const [state, dispatch] = useReducer(GlobalReducer, initialState);

  // Global App settings
  const appConfigs = AppConfigs;

  const setHeaderHeight = (height) => {
    dispatch({
      type: HEADER_HEIGHT,
      payload: height,
    });
  };

  const setFooterHeight = (height) => {
    dispatch({
      type: FOOTER_HEIGHT,
      payload: height,
    });
  };

  const setHamburgerState = (hamburgerState) => {
    dispatch({
      type: HAMBURGER_STATE,
      payload: hamburgerState,
    });
  };

  const setIndication = (indication) => {
    dispatch({
      type: INDICATION,
      payload: indication,
    });
  };

  const setExternalLinkModal = (_modalState) => {
    dispatch({
      type: EXTERNAL_LINK_MODAL,
      payload: _modalState,
    });
  };

  const setExternalLinkURL = (_url) => {
    dispatch({
      type: EXTERNAL_LINK_URL,
      payload: _url,
    });
  };
  const setCurrentPageName = (_page_name) => {
    dispatch({
      type: CURRENT_PAGE_NAME,
      payload: _page_name,
    });
  };

  const setHcpModal = (_modalState) => {
    dispatch({
      type: HCP_MODAL,
      payload: _modalState,
    });
  };

  const setHcpURL = (_url) => {
    dispatch({
      type: HCP_URL,
      payload: _url,
    });
  };

  const setReferences = (_references) => {
    dispatch({
      type: REFERENCES,
      payload: _references,
    });
  };

  useEffect(() => {
    setIsMobile(windowSize.width < 768);
  }, [windowSize.width]);
  return (
    <GlobalContext.Provider
      value={{
        headerHeight: state.headerHeight,
        footerHeight: state.footerHeight,
        hamburgerState: state.hamburgerState,
        currentIndication: state.currentIndication,
        externalLinkModalState: state.externalLinkModalState,
        externalLinkURL: state.externalLinkURL,
        hcpModalState: state.hcpModalState,
        hcpURL: state.hcpURL,
        references: state.references,
        currentPageName: state.currentPageName,
        isMobile,
        setHeaderHeight,
        setFooterHeight,
        setHamburgerState,
        setIndication,
        setExternalLinkModal,
        setExternalLinkURL,
        setHcpModal,
        setHcpURL,
        setReferences,
        setCurrentPageName,
        appConfigs,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
