// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aveed-finder-js": () => import("./../../../src/pages/aveed-finder.js" /* webpackChunkName: "component---src-pages-aveed-finder-js" */),
  "component---src-pages-hcp-404-js": () => import("./../../../src/pages/hcp/404.js" /* webpackChunkName: "component---src-pages-hcp-404-js" */),
  "component---src-pages-hcp-aveed-finder-signup-js": () => import("./../../../src/pages/hcp/aveed-finder-signup.js" /* webpackChunkName: "component---src-pages-hcp-aveed-finder-signup-js" */),
  "component---src-pages-hcp-aveed-safety-information-js": () => import("./../../../src/pages/hcp/aveed-safety-information.js" /* webpackChunkName: "component---src-pages-hcp-aveed-safety-information-js" */),
  "component---src-pages-hcp-clinical-data-js": () => import("./../../../src/pages/hcp/clinical-data.js" /* webpackChunkName: "component---src-pages-hcp-clinical-data-js" */),
  "component---src-pages-hcp-clinical-safety-profile-js": () => import("./../../../src/pages/hcp/clinical-safety-profile.js" /* webpackChunkName: "component---src-pages-hcp-clinical-safety-profile-js" */),
  "component---src-pages-hcp-dosing-and-administration-js": () => import("./../../../src/pages/hcp/dosing-and-administration.js" /* webpackChunkName: "component---src-pages-hcp-dosing-and-administration-js" */),
  "component---src-pages-hcp-get-aveed-js": () => import("./../../../src/pages/hcp/get-aveed.js" /* webpackChunkName: "component---src-pages-hcp-get-aveed-js" */),
  "component---src-pages-hcp-hypogonadism-provider-enrollment-js": () => import("./../../../src/pages/hcp/hypogonadism-provider-enrollment.js" /* webpackChunkName: "component---src-pages-hcp-hypogonadism-provider-enrollment-js" */),
  "component---src-pages-hcp-index-js": () => import("./../../../src/pages/hcp/index.js" /* webpackChunkName: "component---src-pages-hcp-index-js" */),
  "component---src-pages-hcp-paying-for-aveed-js": () => import("./../../../src/pages/hcp/paying-for-aveed.js" /* webpackChunkName: "component---src-pages-hcp-paying-for-aveed-js" */),
  "component---src-pages-hcp-resources-js": () => import("./../../../src/pages/hcp/resources.js" /* webpackChunkName: "component---src-pages-hcp-resources-js" */),
  "component---src-pages-hcp-sitemap-js": () => import("./../../../src/pages/hcp/sitemap.js" /* webpackChunkName: "component---src-pages-hcp-sitemap-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paying-for-aveed-js": () => import("./../../../src/pages/paying-for-aveed.js" /* webpackChunkName: "component---src-pages-paying-for-aveed-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-what-is-hypogonadism-js": () => import("./../../../src/pages/what-is-hypogonadism.js" /* webpackChunkName: "component---src-pages-what-is-hypogonadism-js" */),
  "component---src-pages-what-to-expect-js": () => import("./../../../src/pages/what-to-expect.js" /* webpackChunkName: "component---src-pages-what-to-expect-js" */),
  "component---src-pages-why-aveed-js": () => import("./../../../src/pages/why-aveed.js" /* webpackChunkName: "component---src-pages-why-aveed-js" */)
}

