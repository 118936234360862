import {
  HEADER_HEIGHT,
  FOOTER_HEIGHT,
  HAMBURGER_STATE,
  INDICATION,
  EXTERNAL_LINK_MODAL,
  EXTERNAL_LINK_URL,
  HCP_MODAL,
  HCP_URL,
  REFERENCES,
  CURRENT_PAGE_NAME,
} from "../ActionTypes";
export default (state, action) => {
  switch (action.type) {
    case INDICATION:
      return {
        ...state,
        currentIndication: action.payload,
      };
    case HEADER_HEIGHT:
      return {
        ...state,
        headerHeight: action.payload,
      };
    case FOOTER_HEIGHT:
      return {
        ...state,
        footerHeight: action.payload,
      };
    case HAMBURGER_STATE:
      return {
        ...state,
        hamburgerState: action.payload,
      };
    case EXTERNAL_LINK_MODAL:
      return {
        ...state,
        externalLinkModalState: action.payload,
      };
    case EXTERNAL_LINK_URL:
      return {
        ...state,
        externalLinkURL: action.payload,
      };
    case HCP_MODAL:
      return {
        ...state,
        hcpModalState: action.payload,
      };
    case HCP_URL:
      return {
        ...state,
        hcpURL: action.payload,
      };
    case REFERENCES:
      return {
        ...state,
        references: action.payload,
      };
    case CURRENT_PAGE_NAME:
      return {
        ...state,
        currentPageName: action.payload,
      };
    default:
      return state;
  }
};
