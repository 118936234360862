import { useCallback, useState, useEffect } from 'react';

export default function useWindowSize() {
  const isClient = typeof window === 'object';
  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);
  const handleResize = useCallback(() => {
    setWindowSize(getSize());
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isClient) {
      return false;
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, [handleResize]);

  return windowSize;
}


// Example Usage
// -------------

//  import { useWindowSize } from './custom-hooks';

//   const Example = () => {
//   const size = useWindowSize();

//   return (
//     <div>
//       {size.width}px / {size.height}px
//     </div>
//   );
// };