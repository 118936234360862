import { useState, useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default function useResizeObserver(ELEMENT_REFERENCE) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      setWidth(entries[0].contentRect.width);
      setHeight(entries[0].contentRect.height);
    });

    if (ELEMENT_REFERENCE.current !== null) {
      resizeObserver.observe(ELEMENT_REFERENCE.current);
    }

    return () => void resizeObserver.disconnect();
  }, [ELEMENT_REFERENCE]);

  return { width: width, height: height };
};

// Example Usage
// -------------

//  import { useResizeObserver } from './custom-hooks';

//   const Example = () => {
//   const ref = React.useRef(null);
//   const elementSize = useResizeObserver(ref);

//   return (
//     <div>
//       <p style={{ width: '80%', backgroundColor: 'blue' }} ref={ref}>{`${elementSize.width}px/${elementSize.height}px`}</p>
//     </div>
//   );
// };
